import { useState } from "react";
import api from "../data/api.service";
import { Customer, Product, Supermarket } from "../data/models";
import { useAsyncEffect } from "./async-effect.hook";

export function useSupermarkets() {
	const [supermarkets, _] = useState<Supermarket[]>([])
	useAsyncEffect(async () => {
		const { succeeded, data } = await api.supermarkets.all()
		if (succeeded) _(data)
	}, [])
	return supermarkets
}

export function useCustomers() {
	const [customers, _] = useState<Customer[]>([])
	useAsyncEffect(async () => {
		const { succeeded, data } = await api.customers.all()
		if (succeeded) _(data)
	}, [])
	return customers
}

export function useProducts() {
	const [products, _] = useState<Product[]>([])
	useAsyncEffect(async () => {
		const { succeeded, data } = await api.products.all()
		if (succeeded) _(data)
	}, [])
	return products
}