import { cx } from '../utilities/react.utilities'
import styles from './landing.module.css'

import sancocho from '../assets/sancocho.png'
import header from '../assets/temporada.png'
import { useNavigate } from 'react-router-dom'
import api from '../data/api.service'

const { container, button, big, backdrop_item, content, title } = styles

export default function Landing() {
	const navigate = useNavigate()
	return <>
		<section className={container}>
			<img className={backdrop_item} src={sancocho} alt="" />

			<div className={content} style={{ zIndex: 1 }}>
				<img 
					src={header} alt="Temporada de Sancocho"
					className={title}/>
				<button 
					onClick={async () => {
						const { succeeded, data } = await api.check()
						if (!succeeded) return
						navigate('/enter')
					}}
					className={cx(button, big)}>
					Participa
				</button>
			</div>
		</section>
	</>
}