import styles from './front.module.css'
import title from '../assets/temporada.png'
import { cx } from '../utilities/react.utilities'

import gallinita from '../assets/ingredientes/gallinita.png'
import misabor from '../assets/ingredientes/misabor.png'
import amarillito from '../assets/ingredientes/amarillito.png'
import sazon from '../assets/ingredientes/sazon.png'
import tomate from '../assets/ingredientes/tomate.png'

import Modal from '../components/modal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { distinct, global_hotkey, shuffle } from '../utilities/utilities'
import { Icon } from '../components/faicon'
import api from '../data/api.service'
import State from '../state'

const { container, content, header, text, big, ingredient, big_ingredient, deflate, jiggle } = styles

export default function Play() {
	const [modal, setModal] = useState<{image: string, name: string, description: string}>()
	const navigate = useNavigate()

	const ingredients = useState(shuffle([
		{ image: gallinita,  wins: true,  name: 'Gallinita',        description: 'Sí, también la puedes utilizar para darle más sabor a tus arroces.' },
		{ image: misabor,    wins: true,  name: 'Mi Sabor',         description: 'Sí, también lo puedes utilizar para preparar unas deliciosas habichuelas guisadas.' },
		{ image: amarillito, wins: false, name: 'Amarillito',       description: 'Es un sazón que se utiliza para dar color y sabor a tus platos.' },
		{ image: sazon,      wins: true,  name: 'Sazón Completo',   description: 'Sí, también lo puedes utilizar para sazonar carnes.' },
		{ image: tomate,     wins: false, name: 'Gallinita Tomate', description: 'Es un caldo de pollo con tomates naturales ideal para preparar pastas.' },
	]))[0]

	const [selected, setSelected] = useState<number[]>([])

	const [should_deflate, setDeflate] = useState(false)
	const [should_jiggle, setJiggle] = useState(false)

	return <>
		<section className={container}>
			<div className={cx(content, {
				[deflate]: should_deflate,
				[jiggle]: should_jiggle,
				'pointer-events-none': should_jiggle
			})} style={{ paddingInline: 100 }}>
				<img src={title} alt="Temporada de Sancocho" className={cx(header)}/>

				<div className={cx(text, big)}>
					¿Cuáles son los poductos ideales para preparar tu sancocho?
				</div>

				<div className="flex flex-wrap justify-around" style={{ maxWidth: '80%' }}>
					{ingredients.map((ing, i) => 
						<div 
							key={ing.name} 
							onClick={() => {
								setModal(ing)
								if (ing.wins)
									setSelected(_ => distinct([..._, i]))
							}}
							className={ingredient}
						>
							{selected.includes(i) && <Icon icon='circle-check' className='absolute top-0 right-4 text-green-600' size='2x'
								style={{ filter: 'drop-shadow(rgba(255, 255, 255, 1) -1px 2px 2px)' }}/>}
							<img src={ing.image} alt={ing.name}/>
						</div>
					)}
				</div>
			</div>
		</section>

		{modal && <Modal 
			onDismiss={async () => {
				setModal(undefined)
				if (selected.length === 3) {
					setJiggle(true)
					const { succeeded, data: prize } = await api.customers.register(State.customer!)
					setJiggle(false)
					if (!succeeded) return
					State.won_prize = prize
					setDeflate(true)
					setTimeout(() => navigate('/won'), 500)
				}
			}}
			className="!rounded-full !bg-[#fff100] !p-12"
			style={{ boxShadow: '0 0 15px black' }}
		>
			<div className={big_ingredient}>
				<img src={modal.image} alt={modal.name} />
				<div className={cx(text, 'border-l pl-2 border-[#ed2d21]')}>
					<div style={{ fontSize: '2rem' }}>{modal.name}</div>
					<p style={{ fontSize: '1.25rem', maxWidth: '150px' }}>{modal.description}</p>
				</div>
			</div>
		</Modal>}
	</>
}