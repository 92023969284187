import { useEffect, useState } from "react";
import { Icon } from "../../components/faicon";
import Modal from "../../components/modal";
import { usePagination } from "../../components/paginator";
import { useToast } from "../../components/toaster";
import { SupermarketData } from "../../data/api.models";
import api from "../../data/api.service";
import { Supermarket } from "../../data/models";
import { useAsyncEffect } from "../../hooks/async-effect.hook";
import { useForm, validate } from "../../hooks/form.hook";
import { usePartialState } from "../../hooks/partial-state.hook";
import { mask } from "../../utilities/mask.utils";

function format_address({ region, city, street }: { region: string, city: string, street: string }) {
	return [street, [city, region].join(', ')].join(',\n')
}

export default function Supermarkets() {
	const [is, setState] = usePartialState({
		loading: true,
		removing: undefined as Supermarket | undefined
	})
	const [supermarkets, setSupermarkets] = useState<Supermarket[]>([])
	useAsyncEffect(async () => {
		if (!is.loading) return
		const { succeeded, data } = await api.supermarkets.all()
		if (!succeeded) return
		setSupermarkets(data!)
		setState({ loading: false })
	}, [is.loading])

	const [PageSizer, Paginator, slice] = usePagination(supermarkets.length, 'supermercados')

	const [modal, setModal] = usePartialState({ market: undefined as Supermarket | true | undefined })
	const toast = useToast()
	async function remove(market: Supermarket) {
		setState({ removing: market })
		const { succeeded } = await api.supermarkets.remove(market.id)
		setState({ removing: undefined })
		if (!succeeded) return
		toast('success', 'Supermercado eliminado.')
		setState({ loading: true })
	}
	
	return <section className="p-6">
		<h1 className="mb-6">Supermercados</h1>

		{is.loading
		? <Icon icon='spinner' pulse size='2x'/>
		: <>
		<PageSizer/>
		<table className="table">
			<thead>
				<tr>
					<th>Nombre</th>
					<th>RNC</th>
					<th>Dirección</th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{supermarkets.slice(slice.start, slice.end).map(market =>
					<tr key={market.id}>
						<td>{market.name}</td>
						<td>{market.document}</td>
						<td className="whitespace-pre-wrap">{format_address(market.address)}</td>
						<td>
							<div className="flex gap-2">
								<button title="Editar..." onClick={() => setModal({ market })} className="button">
									<Icon icon="pen-to-square"/>
								</button>
								<button title="Eliminar..." onClick={() => remove(market)} disabled={!!is.removing} className="button hover:bg-red-500 hover:text-white">
									{is.removing !== market
										? <Icon icon="trash-alt"/>
										: <Icon icon='spinner' pulse/>}
								</button>
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
		<Paginator/>

		<button onClick={() => setModal({ market: true })} className="button mt-6">
			<Icon icon="plus"/>
			Agregar supermercado
		</button>
		</>}

		{modal.market && <Modal onDismiss={() => setModal({ market: undefined })}>
			<CreateEditSupermarket market={modal.market} onFinish={() => {
				setModal({ market: undefined })
				setState({ loading: true })
			}}/>
		</Modal>}
	</section>
}

function CreateEditSupermarket({ market: market_, onFinish }: { market: Supermarket | true, onFinish?: () => void }) {
	const [is, setState] = usePartialState({ working: false })
	const market = useForm<SupermarketData>({
		validation: {
			name: validate.required,
			address: {
				region: validate.required,
				city: validate.required,
				street: validate.required
			}
		},
		validate_native: true
	})

	useEffect(() => {
		if (market_ !== true)
			market.setData({ name: market_.name, document: market_.document, address: market_.address })
	}, [])

	const toast = useToast()
	async function create(data: Partial<SupermarketData>) {
		setState({ working: true })
		const { succeeded, data: id } = await api.supermarkets.create(data as any)
		setState({ working: false })
		if (!succeeded) return
		toast('success', 'Supermercado creado.')
		onFinish?.()
	}

	async function edit(data: Partial<SupermarketData>) {
		setState({ working: true })
		const { succeeded } = await api.supermarkets.edit((market_ as Supermarket).id, data as any)
		setState({ working: false })
		if (!succeeded) return
		toast('success', 'Supermercado actualizado.')
		onFinish?.()
	}

	return <form onSubmit={market.submit(market_ === true? create : edit)}>
		<h2>{market_ === true? 'Nuevo' : 'Editar'} supermercado</h2>

		<div className="my-6 space-y-3">
			<div>
				<label htmlFor="name" className="font-medium">Nombre</label><br/>
				<input id="name" {...market.field('name')} className="input w-full" />
			</div>
			<div>
				<label htmlFor="document" className="font-medium">RNC</label><br/>
				<input id="document" {...market.field('document', { format: mask('#-##-#####-#') })} className="input w-full" />
			</div>
			<div>
				<label className="font-medium">Dirección</label><br/>
				<input {...market.field(['address', 'region'])} className="input w-full" title="Provincia" placeholder="Provincia"/><br/>
				<input {...market.field(['address', 'city'])}   className="input w-full" title="Ciudad"    placeholder="Ciudad"/><br/>
				<input {...market.field(['address', 'sector'])} className="input w-full" title="Sector"    placeholder="Sector"/><br/>
				<input {...market.field(['address', 'street'])} className="input w-full" title="Calle"     placeholder="Calle"/>
			</div>
		</div>

		<div className="flex justify-end">
			<button type="submit" disabled={is.working} className="button blue">
				{ !is.working
					? <>
						<Icon icon="save"/>
						{ market_ === true? 'Crear' : 'Guardar' }
					</> : <>
						<Icon icon='spinner' pulse/>
						{ market_ === true? 'Creando...' : 'Guardando...' }
					</> }
			</button>
		</div>
	</form>
}