import { NavigateFunction } from "react-router-dom";
import { ToastFunction } from "../components/toaster";
import State from "../state";
import { CustomerData, PrizeData, SupermarketData } from "./api.models";
import { PrizeSummarized, PrizeAllocation, Supermarket, User, Prize, Customer, Product } from "./models";

function login(username: string, password: string): Promise<Response<{ user: User, token: string }>> {
	return post(api('login'), { username, password });
}

function check(): Promise<Response<User>> {
	return get(api('check'))
}

function supermarkets(): Promise<Response<Supermarket[]>> {
	return get(api('supermarket', 'all'))
}

function create_supermarket(data: SupermarketData): Promise<Response<number>> {
	return post(api('supermarket'), data)
}

function edit_supermarket(id: number, data: SupermarketData): Promise<Response<null>> {
	return put(api('supermarket', id), data)
}

function remove_supermarket(id: number): Promise<Response<null>> {
	return del(api('supermarket', id))
}

function prizes(): Promise<Response<PrizeSummarized[]>> {
	return get(api('prize', 'all'))
}

function create_prize(data: PrizeData): Promise<Response<number>> {
	return post(api('prize'), data)
}

function edit_prize(id: number, data: PrizeData): Promise<Response<null>> {
	return put(api('prize', id), data)
}

function remove_prize(id: number): Promise<Response<null>> {
	return del(api('prize', id))
}

function prize_allocations(): Promise<Response<PrizeAllocation[]>> {
	return get(api('prize', 'allocation', 'all'))
}

function assign_prize(id: number, quantity: number, origin_supermarket_id?: number, destination_supermarket_id?: number): Promise<Response<null>> {
	return put(api('prize', id, 'assign'), { quantity, origin_supermarket_id, destination_supermarket_id })
}

function customers(): Promise<Response<Customer[]>> {
	return get(api('customer', 'all'))
}

function users(): Promise<Response<User[]>> {
	return get(api('user', 'all'))
}

function create_user(username: string, supermarket_id: number | undefined): Promise<Response<{ user_id: number, password: string }>> {
	return post(api('user'), { username, supermarket_id })
}

function reset_user_password(id: number): Promise<Response<string>> {
	return del(api('user', id, 'password'))
}

function remove_user(id: number): Promise<Response<null>> {
	return del(api('user', id))
}

function register_customer(data: CustomerData): Promise<Response<Prize>> {
	return post(api('customer'), data)
}

function products() {
	return get<Product[]>(api('product', 'all'))
}

function create_product(name: string) {
	return post<number>(api('product'), { name })
}

function edit_product(id: number, name: string) {
	return put(api('product', id), { name })
}

function remove_product(id: number) {
	return del(api('product', id))
}

function remove_customer(id: number) {
	return del(api('customer', id))
}

export default {
	login,
	check,
	supermarkets: {
		all:    supermarkets,
		create: create_supermarket,
		edit:   edit_supermarket,
		remove: remove_supermarket
	},
	products: {
		all: products,
		create: create_product,
		edit: edit_product,
		remove: remove_product
	},
	prizes: {
		all: prizes,
		create: create_prize,
		edit: edit_prize,
		remove: remove_prize,

		allocations: prize_allocations,
		assign: assign_prize
	},
	users: {
		all: users,
		create: create_user,
		reset_password: reset_user_password,
		remove: remove_user
	},
	customers: {
		all: customers,
		register: register_customer,
		remove: remove_customer
	},
	resource: (id: string) => api('resource', id)
}

// ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

type Response<T> = { succeeded: true, data: T, error: null } | { succeeded: false, data: null, error: { code: string, message: string } }

const api_basepoint = window.location.hostname == 'localhost'
	? 'https://localhost:7052'
	: 'https://sancocho-api.transnegrd.com'

const error = (code: string, message: string): Response<null> => ({
	succeeded: false, data: null, error: { code, message }
});

export class ApiService {
	static toaster?: ToastFunction;
	static go_to_login?: () => void;
}

async function wrap_request<T>(url: string, request: RequestInit): Promise<Response<T>> {
	try {
		const response = await fetch(url, request);
		let data: Response<T>;
		if (response.ok) {
			data = await response.json();
		} else {
			const content = await response.text()
			const failure = !content? error(`HTTP_${response.status}`, `HTTP: ${response.statusText}`) : JSON.parse(content);
			if (failure.title)
				data = error(
					response.statusText.toUpperCase().replace(/\s/g, '_'), 
					`${failure.title}\n${(Object.values(failure.errors) as string[][]).reduce((a, b) => a.concat(b)).map(e => ` - ${e}`).join('\n')}`
				) as any;
			else
				data = failure;
			if (failure.error?.code === 'NOT_AUTHENTICATED') {
				State.clear();
				ApiService.go_to_login?.()
			}
		}
		if (data.succeeded)
			console.debug(`response from ${request.method?.toLowerCase()} to ${url.replace(api_basepoint, '')}:`, data.data);
		else {
			console.debug(`failure from ${request.method?.toLowerCase()} to ${url.replace(api_basepoint, '')}:`, data.error)
			ApiService.toaster?.('error', data.error.message);
		}
		return data;
	} catch (ex) {
		const _error = error('UNKNOWN', (ex as any).message) as any;
		console.error(`error on ${request.method?.toLowerCase()} request to ${url.replace(api_basepoint, '')}:`, _error);
		ApiService.toaster?.('error', _error.error.message);
		return _error;
	}
	
}

async function get<T>(url: string): Promise<Response<T>> {
	console.debug(`get ${url.replace(api_basepoint, '')}`);
	return await wrap_request(url, {
		method: 'GET',
		headers: State.auth_token
			? { Authorization: State.auth_token as string }
			: undefined 
	});
}

async function post<T>(url: string, body?: any): Promise<Response<T>> {
	console.debug(`post to ${url.replace(api_basepoint, '')}:`, body);
	return await wrap_request(url, {
		method: 'POST',
		headers: {
			...(State.auth_token? { Authorization: State.auth_token } : { }),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	});
}

async function put<T>(url: string, body?: any): Promise<Response<T>> {
	console.debug(`put to ${url.replace(api_basepoint, '')}:`, body);
	return await wrap_request(url, {
		method: 'PUT',
		headers: {
			...(State.auth_token? { Authorization: State.auth_token } : { }),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	});
}

async function del<T>(url: string): Promise<Response<T>> {
	console.debug(`delete ${url.replace(api_basepoint, '')}`);
	return await wrap_request(url, {
		method: 'DELETE',
		headers: State.auth_token
			? { Authorization: State.auth_token as string }
			: undefined 
	});
}

const api = (...fragments: (string | number)[]): string => [api_basepoint].concat(<any>fragments).join('/')