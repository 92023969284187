import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import App from './app';
import ReactDOM from 'react-dom';
import reportWebVitals from './utilities/reportWebVitals';
import { HashRouter as Router, useLocation } from 'react-router-dom';
import './utilities/extensions';
import './styles.css';
import Toaster from './components/toaster';

library.add(fas, far, fab);

ReactDOM.render(
  <Router>
    <Container>
      <App />
    </Container>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();

function Container({ children }: { children: any }) {
  const is_backoffice = useLocation().pathname.startsWith('/backoffice')
  return <Toaster {...(!is_backoffice? { bottom: true, center: true } : { top: true, right: true })}>
    {children}
  </Toaster>
}