import styles from './backoffice.module.css'

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../../components/faicon";
import { cx } from '../../utilities/react.utilities';
import Supermarkets from './supermarkets';
import Prizes from './prizes';
import Customers from './customers';
import Users from './users';
import State from '../../state';
import Products from './products';

export default function Backoffice() {
	const navigate = useNavigate()
	const location = useLocation()

	if (!State.auth_token)
		navigate('/login')

	function nav(path: string, label: string, icon?: IconProp) {
		let tloc = location.pathname.replace(/\/$/, '')
		path = path.replace(/\/$/, '')

		return <li
			onClick={() => navigate(path)}
			className={cx({
				[styles.active]: tloc.endsWith(path)
			})}
		>
			{icon && <Icon icon={icon}/>}
			{label}
		</li>
	}

	return <section className={styles.container}>
		<ul className={styles.sidebar}>
			{nav('markets',   'Supermercados', 'shop')}
			{nav('products',  'Productos',     'cart-shopping')}
			{nav('prizes',    'Premios',       'gift')}
			{nav('players',   'Participantes', 'users')}
			{nav('users',     'Usuarios',      'user-shield')}
			<div className="flex-1"></div>
			<li onClick={() => {
				State.clear()
				navigate('/login')
			}}>
				<Icon icon="sign-out"/>
				Salir
			</li>
		</ul>
		<section className={styles.content}>
			<Routes>
				<Route path="dashboard" element={undefined}/>
				<Route path="markets"   element={<Supermarkets/>}/>
				<Route path="products"  element={<Products/>}/>
				<Route path="prizes"    element={<Prizes/>}/>
				<Route path="players"   element={<Customers/>}/>
				<Route path="users"     element={<Users/>}/>

				<Route path="*" element={<Navigate to="players"/>}/>
			</Routes>
		</section>
	</section>
}