import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../components/faicon'
import api from '../data/api.service'
import State from '../state'
import { cx } from '../utilities/react.utilities'

import styles from './front.module.css'

export default function Won() {
	const [exit, setExit] = useState(false)
	useEffect(() => {
		setTimeout(() => setExit(true), 2_500)
	}, [])

	const navigate = useNavigate()
	return <>
		<section className={styles.container}>
			<Icon icon='xmark' size='4x'
				onClick={() => {
					navigate('/landing')
					State.customer = State.won_prize = undefined
				}}
				style={{ transition: 'opacity 1s' }}
				className={cx("text-red-600 absolute top-8 left-12 opacity-0 cursor-pointer", {
					'opacity-100': exit,
					[styles.jiggle]: exit
				})}/>
			<div className={cx(styles.content, styles.inflate)}>
				<h1 className={cx(styles.text, styles.huge, styles.jiggle)}>¡Ganaste!</h1>

				<div>
					{State.won_prize?.image_resource_id && <img src={api.resource(State.won_prize.image_resource_id)} alt={State.won_prize?.name}
						className={cx(styles.pulse, styles.prize, 'max-h-[60vh]')}/>}
					<h1 className={cx(styles.text, styles.bigger, 'relative z-10')}>{State.won_prize?.name}</h1>
				</div>
			</div>
		</section>
	</>
}