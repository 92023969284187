import styles from './image-input.module.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useState } from "react";
import { cx } from '../utilities/react.utilities';

interface ImageInputProperties {
	className?: string
	style?: CSSProperties
	square?: boolean
	vertical?: boolean
	horizontal?: boolean
	max_size?: number
	contain?: boolean
	defaultImage?: string
	onInput: (file_name: string, image_data: string) => void
}
export default function ImageInput({ className, style, square, vertical, horizontal, max_size, contain, defaultImage, onInput }: ImageInputProperties) {
	const toast = (...args: any) => {} //useToast();
	const [image, setImage] = useState(
		(defaultImage
			? { name: '', image_data: defaultImage }
			: null) as
		{ name: string; image_data: string; } | null
	)

	async function process_file(files: FileList | null) {
		if (!files?.length) return;
		const file = files[0];
		if (max_size !== undefined && file.size > max_size) {
			toast?.('warning', `El tamáño de la imágen es mayor que el permitido (${max_size} bytes).`);
			return;
		}
		const reader = new FileReader();
		reader.addEventListener('load', () => {
			setImage({name: file.name, image_data: reader.result as string});
			onInput(file.name, reader.result as string);
		});
		reader.readAsDataURL(file);
	}

	return (
		<div style={style} className={cx(styles.image_input, {
			[styles.square]: square,
			[styles.horizontal]: horizontal,
			[styles.vertical]: vertical
		}, className)}>
			{image
				? <img src={image.image_data} title={image.name} className={cx({ [styles.contain]: contain })}/>
				: <FontAwesomeIcon icon={['far', 'image']}/> }
			<div className={cx(styles.corner, styles.top,    styles.right)}></div>
			<div className={cx(styles.corner, styles.top,    styles.left )}></div>
			<div className={cx(styles.corner, styles.bottom, styles.right)}></div>
			<div className={cx(styles.corner, styles.bottom, styles.left )}></div>
			<input 
				type="file" accept="image/jpeg,image/png"
				onChange={({ target: { files } }) => process_file(files)}/>
		</div>
	);
}