import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Icon } from "../components/faicon"
import { useForm, validate } from "../hooks/form.hook"
import { usePartialState } from "../hooks/partial-state.hook"

import wood from '../assets/wood.jpg'
import api from "../data/api.service"
import State from "../state"

export default function Login() {
	const login_form = useForm({
		validation: {
			username: validate.required,
			password: validate.required
		},
		validate_native: true
	})
	const [show, setVisibility] = useState(false)

	const [is, setState] = usePartialState({ working: false })
	const navigate = useNavigate()
	async function login({ username, password }: typeof login_form.data) {
		setState({ working: true })
		const { succeeded, data } = await api.login(username!, password!)
		setState({ working: false })
		if (!succeeded) return
		State.auth_token = data.token
		State.user = data.user
		navigate(data.user.supermarket == null? '/backoffice' : '/landing')
	}

	return <section 
		className="fixed inset-0 grid place-items-center" 
		style={{
			backgroundImage: `url(${wood})`,
			backgroundSize: 'cover'
		}}>
		<form onSubmit={login_form.submit(login)} className="bg-white p-4 rounded-xl" autoComplete="off">
			<h1 className="text-xl font-bold">¡Bienvenido!</h1>

			<div className="mt-6">
				<label htmlFor="username">Username:</label>
				<input id="username" {...login_form.field('username')} className="input block w-full" />
			</div>

			<div>
				<label htmlFor="password">Contraseña:</label>
				<div className="input">
					<input id="password" {...login_form.field('password', { type: show? 'text' : 'password' })}/>
					<Icon
						icon={ ['far', show? 'eye-slash' : 'eye'] }
						onClick={() => setVisibility(v => !v)}/>
				</div>
			</div>

			<div className="flex justify-end mt-6">
				<button type="submit" disabled={is.working} className="button red">
					{ !is.working? <>
						<Icon icon="sign-in"/>
						Ingresar
					</> : <>
						<Icon icon="spinner" pulse/>
						Ingresando...
					</>}
				</button>
			</div>
		</form>
	</section>
}