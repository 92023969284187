import styles from './front.module.css'

import title from '../assets/temporada.png'
import { cx } from '../utilities/react.utilities'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { mask } from '../utilities/mask.utils'
import { useForm, validate } from '../hooks/form.hook'
import { useToast } from '../components/toaster'
import State from '../state'
import { Icon } from '../components/faicon'
import { useProducts } from '../hooks/data.hooks'
import { CustomerData } from '../data/api.models'

const { container, content, input, button, header, text, jiggle } = styles

export default function Enter() {
	const customer = useForm<CustomerData>({
		initial: {
			name: '',
			document: '',
			phone: '',
			email: '',
			products: [{ id: 0, quantity: 0 }]
		},
		validation: {
			name: validate.required,
			document: d => validate.required(d) || validate.cedula(d),
			phone: p => validate.required(p) || validate.phone(p),
			email: e => !e? null : validate.email(e),
			products: {
				id: (v: number) => Number(v) == 0? 'Requerido.' : undefined,
				quantity: (v: number) => Number(v) == 0? 'Requerido.' : undefined
			}
		}
	})
	const [agreed, setAgreed] = useState(false)

	const navigate = useNavigate()
	const toast = useToast()
	function play(data: typeof customer.data) {
		if (!agreed) {
			toast('warning', 'Debe aceptar los terminos y condiciones')
			return 
		}
		State.customer = data as any
		navigate('/play')
	}

	const products = useProducts()

	return <>
		<section className={cx(container)}>
			<div style={{ backgroundColor: '#fff100' }} className="absolute z-50 h-4 inset-x-0 top-0"/>
			<div style={{ backgroundColor: '#fff100' }} className="absolute z-50 h-4 inset-x-0 bottom-0"/>
			<form onSubmit={customer.submit(play)} className={cx(content, 'overflow-y-auto py-8 !my-8')} style={{ maxHeight: '95vh', width: '95%' }} autoComplete='off'>
				<img src={title} alt="Temporada de Sancocho" className={cx(header)}/>
				
				<div className="flex flex-col">
					<label htmlFor="name" className={text}>Nombre:</label>
					<input id="name" {...customer.field('name')} className={input}/>
				</div>	

				<div className="flex flex-col">
					<label htmlFor="document" className={text}>Cédula:</label>
					<input id="document" inputMode='numeric' {...customer.field('document', { format: mask('###-#######-#') })} className={input}/>
				</div>	

				<div className="flex flex-col">
					<label htmlFor="phone" className={text}>Número de teléfono:</label>
					<input id="phone" inputMode='tel' {...customer.field('phone', { type: 'tel', format: mask('(###) ###-####') })} className={input}/>
				</div>

				<div className="flex flex-col">
					<label htmlFor="email" className={text}>Correo electrónico (opcional):</label>
					<input id="email" inputMode='email' {...customer.field('email', { type: 'email' })} className={input}/>
				</div>

				<div className="flex flex-col">
					<label className={text}>Supermercado:</label>
					<input className={input} readOnly value={State.user?.supermarket?.name}/>
				</div>

				<div className="flex flex-col">
					<label className={text} style={{ width: 'min(350px, 90vw)' }}>Productos:</label>
					{customer.data.products?.map((p, i) => 
					<div className="flex rounded-xl bg-white py-1 px-3 shadow-md mb-2" style={{ width: 'min(350px, 90vw)' }}>
						<input {...customer.field(['products', i, 'quantity'], { type: 'number', min: 1 })} className="border-r" placeholder="Cantidad"/>
						<select
							{...customer.field(['products', i, 'id'], { type: 'number' })}
							className="bg-white rounded-r-xl" style={{ flexBasis: '200%' }}
						>
							<option value={0} disabled>(Seleccionar producto)</option>
							{products.map(product =>
							<option value={product.id}>{product.name}</option>)}
						</select>
						<button 
							type='button'
							onClick={() => {
								customer.setData(({ products }) => ({ products: products!.filter((_, pi) => pi !== i) }))
							}}
							className="text-red-600 ml-4"
						>
							<Icon icon={['far', 'trash-alt']}/>
						</button>
					</div>)}
					<button 
						type='button' 
						onClick={() => customer.setData(({ products }) => ({
							products: [...products!, { id: 0, quantity: 0 }]
						}))} 
						className="max-w-max ml-2">
						<Icon icon='plus'/> Agregar
					</button>
				</div>

				<div className="flex items-baseline gap-2">
					<input 
						type="checkbox" 
						id="agree" 
						checked={agreed}
						onChange={({ target: { checked } }) => setAgreed(checked)}
						className='cursor-pointer h-[1.2rem] w-[1.2rem]' 
						style={{ filter: 'hue-rotate(135deg)' }}/>
					<label htmlFor="agree" className={cx("max-w-[330px] cursor-pointer", text)}>Terminos y Condiciones: <br/>Por este medio doy permiso para que Nestlé me contacte con Ofertas y Promociones</label>
				</div>

				<button type='submit' className={cx(button, 'mt-8', { 'opacity-50': !agreed })}>
					{!agreed && <Icon icon='lock'/>}
					Participa
				</button>
			</form>
		</section>
	</>
}