import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useToast } from "./components/toaster"
import { ApiService } from "./data/api.service"
import Backoffice from "./pages/backoffice/backoffice"
import Enter from "./pages/enter"
import Landing from "./pages/landing"
import Login from "./pages/login"
import Play from "./pages/play"
import Won from "./pages/won"

export default function App() {
  const navigate = useNavigate()

  ApiService.toaster = useToast()
  ApiService.go_to_login = () => navigate('/login')

  return <Routes>
    <Route path="login" element={<Login/>}/>

    <Route path="landing" element={<Landing/>}/>
    <Route path="enter"   element={<Enter/>}/>
    <Route path="play"    element={<Play/>}/>
    <Route path="won"     element={<Won/>}/>

    <Route path="backoffice/*" element={<Backoffice/>}/>

    <Route path="*" element={<Navigate to="/login"/>}/>
  </Routes>
}