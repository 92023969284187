declare global {
	interface Array<T> {
		group<K>(key: (item: T) => K): { key: K, items: T[] }[];
		order_by<K>(key: (item: T) => K, reverse?: boolean): T[];
		distinct<K>(key?: (item: T) => K): T[];
		interspace(item: T): T[];
		chunk(length: number): T[][];
		split_into(length: number): T[][];
	}

	interface String {
		chunk(length: number): string[];
		toTitleCase(): string;
	}
}

Array.prototype.group ??= function<T, K>(this: T[], key: (item: T) => K): { key: K, items: T[] }[] {
	const groups = new Map<K, T[]>();
	for (let item of this) {
		const item_key = key(item);
		if (groups.has(item_key))
			groups.get(item_key)?.push(item);
		else
			groups.set(item_key, [item]);
	}
	return Array.from(groups.entries()).map(([key, items]) => ({ key, items }));
};

Array.prototype.order_by ??= function<T, K>(this: T[], key: (item: T) => K, reverse: boolean = false): T[] {
	if (reverse)
		return this.sort((a, b) => <any>key(b) - <any>key(a));
	return this.sort((a, b) => <any>key(a) - <any>key(b));
};

Array.prototype.distinct ??= function<T, K>(this: T[], key?: (item: T) => K): T[] {
	if (key)
		return this.filter((item, index) => {
			const item_key = key(item);
			return this.findIndex(i => item_key == key(i)) === index;
		});
	return this.filter((item, index) => this.indexOf(item) === index);
};

Array.prototype.interspace ??= function<T>(this: T[], item: T): T[] {
	if (this.length <= 1)
		return this.slice();
	const result: T[] = [this[0]];
	for (let element of this.slice(1)) {
		result.push(item);
		result.push(element);
	}
	return result;
}

Array.prototype.chunk ??= function<T>(this: T[], length: number): T[][] {
	if (this.length <= length)
		return [this.slice()];
	const chunks: T[][] = [];
	for (let i = 0; i < this.length; i += length)
		chunks.push(this.slice(i, i + length));
	return chunks;
}

Array.prototype.split_into ??= function<T>(this: T[], count: number): T[][] {
	return this.chunk(Math.round(this.length / count));
}

String.prototype.chunk ??= function(this: string, length: number): string[] {
	return Array.from(this).chunk(length).map(chars => chars.join());
}

String.prototype.toTitleCase ??= function(this: string): string {
	if (!this.trim().length) return '';
	return this.trim().split(' ').filter(_ => _)
		.map(word => word[0].toUpperCase() + word.substring(1).toLowerCase())
		.join(' ');
}

export {};