import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Icon } from "../../components/faicon"
import { usePagination } from "../../components/paginator"
import { useToast } from "../../components/toaster"
import api from "../../data/api.service"
import { useCustomers } from "../../hooks/data.hooks"
import { date, export_players_as_xlsx, format_datetime, format_shortdate, matches } from "../../utilities/utilities"

export default function Customers() {
	const customers = useCustomers()
	const [filter, setFilter] = useState('')
	const filtered = filter? customers.filter(c => matches(c, filter)) : customers
	const [PageSizer, Paginator, slice] = usePagination(filtered.length, 'participantes')

	function export_all() {
		export_players_as_xlsx(customers, `${format_shortdate(Date.now())}_participantes_sancocho.xlsx`)
	}

	function export_filtered() {
		export_players_as_xlsx(filtered, `${format_shortdate(Date.now())}_participantes_sancocho_${filter.toLowerCase().replaceAll(/\s/g, '-')}.xlsx`)
	}

	const toast = useToast()
	const navigate = useNavigate()

	return <section className="p-6">
		<h1 className="mb-6">Participantes</h1>

		<div className="flex gap-8">
			<PageSizer/>
			<input
				type="text"
				value={filter}
				onChange={({ target: { value } } ) => setFilter(value)}
				placeholder='🔍 Filtrar...'
				className="input" />
		</div>
		<div className="flex gap-4 mt-2">
			<button onClick={export_all} className="button">
				<Icon icon={['far', 'file-excel']}/>
				Exportar todos (.xlsx)
			</button>
			<button onClick={export_filtered} className="button">
				<Icon icon={['far', 'file-excel']}/>
				Exportar filtrados (.xlsx)
			</button>
		</div>
		<table className="table">
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Cédula</th>
					<th>Teléfono</th>
					<th>Correo electrónico</th>
					<th>Supermercado</th>
					<th>Premio</th>
					<th>Productos</th>
					<th>Fecha</th>
					<th className="w-0"></th>
				</tr>
			</thead>
			<tbody>
				{filtered.slice(slice.start, slice.end).map(customer =>
					<tr>
						<td>{customer.name}</td>
						<td>{customer.document}</td>
						<td>{customer.phone}</td>
						<td>{customer.email}</td>
						<td>{customer.supermarket.name}</td>
						<td>{customer.prize.name}</td>
						<td>
							{(customer.products?.length > 0) && <table className="!table w-full">
								<thead>
									<tr>
										<th>Cantidad</th>
										<th>Nombre</th>
									</tr>
								</thead>
								<tbody>
									{customer.products.map((product, i) =>
									<tr key={i}>
										<td>{product.quantity}</td>
										<td>{product.name}</td>
									</tr>)}
								</tbody>
							</table>}
						</td>
						<td>{format_datetime(customer.date)}</td>
						<td>
							<button
								title="Eliminar..."
								onClick={async () => {
									if (window.confirm('¿Seguro que desas eliminar este ganador?')) {
										const { succeeded } = await api.customers.remove(customer.id)
										if (succeeded) {
											toast('success', 'Ganador eliminado.')
											navigate('/backoffice')
										}
									}
								}}
								className="button hover:bg-red-500 hover:text-white"
							>
								<Icon icon={['far', 'trash-alt']}/>
							</button>
						</td>
					</tr>
				)}
			</tbody>
		</table>
		<Paginator/>
	</section>
}