import { useState } from "react"
import { Icon } from "../../components/faicon"
import Modal from "../../components/modal"
import { usePagination } from "../../components/paginator"
import { useToast } from "../../components/toaster"
import api from "../../data/api.service"
import { User } from "../../data/models"
import { useAsyncEffect } from "../../hooks/async-effect.hook"
import { useSupermarkets } from "../../hooks/data.hooks"
import { useForm, validate } from "../../hooks/form.hook"
import { usePartialState } from "../../hooks/partial-state.hook"
import { format_datetime, matches } from "../../utilities/utilities"

export default function Users() {
	const [is, setState] = usePartialState({
		loading: true,
		resetting: undefined as User | undefined,
		removing: undefined as User | undefined,
	})
	const busy = Object.values(is).some(_ => !!_)

	const [users, setUsers] = useState<User[]>([])
	useAsyncEffect(async () => {
		if (!is.loading) return
		const { succeeded, data } = await api.users.all()
		if (!succeeded) return
		setUsers(data)
		setState({ loading: false })
	}, [is.loading])

	const [filter, setFilter] = useState('')
	const filtered = filter? users.filter(u => matches(u, filter)) : users

	const [PageSizer, Paginator, slice] = usePagination(filtered.length, 'usuarios')

	const [modal, setModal] = usePartialState({ create: false })
	const toast = useToast()

	async function reset_password(user: User) {
		setState({ resetting: user })
		const { succeeded, data: password } = await api.users.reset_password(user.id)
		setState({ resetting: undefined })
		if (!succeeded) return
		toast('success', 'Contraseña reestablecida.')
		toast('info', `Contraseña de usuario ${user.username}: \n${password}`, 'keep')
	}

	async function remove(user: User) {
		setState({ resetting: user })
		const { succeeded } = await api.users.remove(user.id)
		setState({ resetting: undefined })
		if (!succeeded) return
		toast('success', 'Usuario removido.')
		setState({ loading: true })
	}

	return <section className="p-6">
		<h1 className="mb-6">Usuarios</h1>

		{is.loading
			? <Icon icon='spinner' pulse size='2x'/>
			: <>
				<div className="flex gap-8">
					<PageSizer/>
					<input
						type="text"
						value={filter}
						onChange={({ target: { value: filter } }) => setFilter(filter)}
						placeholder="🔍 Filtrar..."
						className="input"
					/>
				</div>
				<table className="table">
					<thead>
						<tr>
							<th>Username</th>
							<th>Supermercado</th>
							<th>Fecha creado</th>
							<th>Fecha último login</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filtered.slice(slice.start, slice.end).map(user =>
							<tr>
								<td>{user.username}</td>
								<td>{user.supermarket?.name}</td>
								<td>{format_datetime(user.date_created)}</td>
								<td>{user.date_last_login && format_datetime(user.date_last_login)}</td>
								<td>
									<div className="flex gap-2">
										<button
											title="Resetear contraseña"
											disabled={busy}
											onClick={() => reset_password(user)}
											className="button hover:bg-yellow-500 active:bg-yellow-600"
										>
											{is.resetting !== user
												? <Icon icon="rotate"/>
												: <Icon icon="spinner" pulse/>}
										</button>
										<button 
											title="Eliminar usuario"
											disabled={busy}
											onClick={() => remove(user)}
											className="button hover:bg-red-500 hover:text-white active:bg-red-600"
										>
											{is.removing !== user
												? <Icon icon="trash-alt"/>
												: <Icon icon="spinner" pulse/>}
										</button>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<Paginator/>

				<button onClick={() => setModal({ create: true })} className="button mt-6">
					<Icon icon='user-plus'/>
					Crear usuario
				</button>

				{modal.create && <Modal onDismiss={() => setModal({ create: false })}>
					<CreateUser onCreated={() => {
						setState({ loading: true })
						setModal({ create: false })
					}}/>
				</Modal>}
			</>}
	</section>
}

function CreateUser(props: { onCreated?: (id: number) => void }) {
	const user = useForm({
		initial: {
			username: '',
			supermarket: 0 as number | undefined
		},
		validation: {
			username: _ => validate.required(_),
		}
	})

	const supermarkets = useSupermarkets()

	const [working, setWorking] = useState(false)
	const toast = useToast()
	async function create(data: typeof user.data) {
		setWorking(true)
		const { succeeded, data: result } = await api.users.create(data.username!, data.supermarket? Number(data.supermarket) : undefined)
		setWorking(false)
		if (!succeeded) return
		toast('success', 'Usuario creado.')
		toast('info', `Contraseña del usuario ${data.username}: \n${result.password}`, 'keep')
		props.onCreated?.(result.user_id)
	}

	return <form onSubmit={user.submit(create)} autoComplete='off'>
		<h2 className="mb-6">Crear usuario</h2>

		<div>
			<div>
				<label htmlFor="username" className="font-medium">Username:</label><br/>
				<input id="username" {...user.field('username')} className="input w-full" />
			</div>
			<div>
				<label htmlFor="supermarket" className="font-medium">Supermercado:</label><br/>
				<select id="supermaket" {...user.field('supermarket', { type: 'number' })} className="input w-full">
					<option value={0}>(Administrador)</option>
					{supermarkets.map(market =>
						<option value={market.id}>{market.name}</option>
					)}
				</select>
			</div>
		</div>

		<div className="flex justify-end mt-6">
			<button disabled={working} className="button blue">
				{!working
					? <>
						<Icon icon='user-plus'/>
						Crear
					</> : <>
						<Icon icon='spinner' pulse/>
						Creando...
					</>}
			</button>
		</div>
	</form>
}