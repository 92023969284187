import { useEffect, useState } from "react";
import { Icon } from "../../components/faicon";
import ImageInput from "../../components/image-input";
import Modal from "../../components/modal";
import { usePagination } from "../../components/paginator";
import { useToast } from "../../components/toaster";
import { PrizeData } from "../../data/api.models";
import api from "../../data/api.service";
import { PrizeSummarized, PrizeAllocation } from "../../data/models";
import { useAsyncEffect } from "../../hooks/async-effect.hook";
import { useForm, validate } from "../../hooks/form.hook";
import { usePartialState } from "../../hooks/partial-state.hook";
import { cx } from "../../utilities/react.utilities";

export default function Prizes() {
	const [mode, setMode] = useState(0)

	const [is, setState] = usePartialState({ loading_prizes: true, loading_allocations: false })
	const [prizes, setPrizes] = useState<PrizeSummarized[]>([])
	useAsyncEffect(async () => {
		if (!is.loading_prizes) return
		const { succeeded, data } = await api.prizes.all()
		if (!succeeded) return
		setPrizes(data!)
		setState({ loading_prizes: false })
	}, [is.loading_prizes])

	const [modal, setModal] = usePartialState({
		prize: undefined as PrizeSummarized | true | undefined,
		restock: undefined as { prize: PrizeSummarized, adding: boolean } | undefined,
		transfer: undefined as {
			id: number,
			prize: {
				id: number
				name: string
		
				quantity: number
				won: number
			}
		} | undefined,
		assign: undefined as { id: number, name: string, quantity: number } | undefined,
		unassign: undefined as { supermarket_id: number, prize: { id: number, name: string, quantity: number } } | undefined,
		image: undefined as string | undefined
	})

	const [PageSizer, Paginator, slice] = usePagination(prizes.length, 'premios')

	function all_prizes() {
		return is.loading_prizes? <Icon icon='spinner' pulse size='2x'/> : <>
		<PageSizer className="mt-6"/>
		<table className="table">
			<thead>
				<tr>
					<th></th>
					<th>Nombre</th>
					<th>Total</th>
					<th>Asignados</th>
					<th>Sin asignar</th>
					<th>Ganados</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{prizes.slice(slice.start, slice.end).map(prize =>
					<tr key={prize.id}>
						<td>
							{prize.image_resource_id && 
							<img src={api.resource(prize.image_resource_id)} 
								onMouseEnter={() => setModal({ image: api.resource(prize.image_resource_id!) })}
								onMouseLeave={() => setModal({ image: undefined })}
								className="h-[50px] w-[50px] min-h-[50px] min-w-[50px] object-contain cursor-zoom-in"/>}
						</td>
						<td>{prize.name}</td>
						<td>{prize.counts.total}</td>
						<td>{prize.counts.assigned}</td>
						<td>{prize.counts.unassigned}</td>
						<td>{prize.counts.won}</td>
						<td>
							<div className="flex gap-2">
								<button
									title="Agregar" 
									onClick={() => setModal({ restock: { prize, adding: true  } })} 
									className="button"
								>
									<Icon icon="plus"/>
								</button>
								<button 
									title="Remover" 
									onClick={() => setModal({ restock: { prize, adding: false } })} 
									className="button"
								>
									<Icon icon="minus"/>
								</button>
							</div>
						</td>
						<td>
							<div className="flex gap-2">
								<button title="Editar..." onClick={() => {
									setModal({ prize })
									
								}} className="button">
									<Icon icon="pen-to-square"/>
								</button>
								<button title="Eliminar..." className="button hover:bg-red-500 hover:text-white">
									<Icon icon="trash-alt"/>
								</button>
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
		<Paginator/>

		<button onClick={() => setModal({ prize: true })} className="button mt-6">
			<Icon icon="plus"/>
			Agregar premio
		</button>

		{modal.restock && <Modal onDismiss={() => setModal({ restock: undefined })}>
			<RestockPrize prize={modal.restock.prize} adding={modal.restock.adding} onFinish={() => {
				setState({ loading_prizes: true })
				setModal({ restock: undefined })
			}}/>
		</Modal>}

		{modal.prize && <Modal onDismiss={() => setModal({ prize: undefined })}>
			<CreateEditPrize prize={modal.prize} onFinish={() => {
				setState({ loading_prizes: true })
				setModal({ prize: undefined })
			}}/>
		</Modal>}

		{modal.image && <Modal
			className="pointer-events-none !bg-transparent !shadow-none"
			backdropClassName="pointer-events-none">
			<img src={modal.image} className='max-h-[95vh]'/>
		</Modal>}
		</>
	}

	const [prizeAllocations, setPrizeAllocations] = useState<PrizeAllocation[] | undefined>()
	useAsyncEffect(async () => {
		if (!is.loading_allocations) return
		const { succeeded, data } = await api.prizes.allocations()
		if (!succeeded) return
		setPrizeAllocations(data!)
		setState({ loading_allocations: false })
		setMode(1)
	}, [is.loading_allocations])

	const [ssuper, setSupermarket] = useState<number | undefined>()
	const supermarkets = prizeAllocations?.map(pa => pa.supermarket)
	const allocation   = prizeAllocations?.find(pa => pa.supermarket?.id == ssuper)

	const toast = useToast()

	function per_supermarket() {
		return is.loading_allocations? <Icon icon='spinner' pulse size='2x'/> : <>
		<select
			value={ssuper}
			onChange={({ target: { value } }) => {
				setSupermarket(value == 'undefined'? undefined : Number(value))
			}}
			className="input mt-6">
			{supermarkets?.map((market, i) => 
				<option key={i} value={market?.id ?? 'undefined'}>{ market?.name ?? '(Sin asignar)' }</option>
			)}
		</select>

		<table className="table">
			<thead>
				<tr>
					<th>Premio</th>
					<th>{ssuper == null? 'Cantidad' : 'Total'}</th>
					{ssuper != null && <>
						<th>En inventario</th>
						<th>Ganados</th>
					</>}
					<th></th>
				</tr>
			</thead>
			<tbody>
				{allocation?.prizes?.map(prize => 
					<tr key={prize.id}>
						<td>{prize.name}</td>
						<td>{prize.quantity + prize.won}</td>
						{ssuper != null && <>
							<td>{prize.quantity}</td>
							<td>{prize.won}</td>
						</>}
						<td>
							<div className="flex gap-2">
								{ssuper != null
									? <>
										<button 
											title="Transferir a otro supermercado..." 
											onClick={() => setModal({ transfer: { id: allocation.supermarket!.id, prize }})} 
											className="button"
										>
											<Icon icon="right-left"/>
										</button>
										<button
											title="Des-asignar..."
											onClick={() => setModal({ unassign: { supermarket_id: allocation.supermarket!.id, prize } })}
											className="button"
										>
											<Icon icon="minus"/>
										</button>
									</> : <>
										<button title="Asignar..." onClick={() => {
											if ((supermarkets?.length ?? 0) > 1)
												setModal({ assign: prize })
											else 
												toast('error', 'No existen supermercados a los cuales asignar inventario.')
										}} className="button">
											<Icon icon="truck-arrow-right"></Icon>
										</button>
									</>}
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>

		{modal.assign && <Modal onDismiss={() => setModal({ assign: undefined })}>
			<AssignPrizes supermarkets={supermarkets as any} prize={modal.assign}
				onFinish={() => {
					setState({ loading_allocations: true })
					setState({ loading_prizes: true })
					setModal({ assign: undefined })
				}}/>
		</Modal>}

		{modal.transfer && <Modal onDismiss={() => setModal({ transfer: undefined })}>
			<TransferPrize supermarkets={supermarkets as any} supermarket_id={modal.transfer.id} prize={modal.transfer.prize}
				onFinish={() => {
					setState({ loading_allocations: true })
					setModal({ transfer: undefined })
				}}/>
		</Modal>}

		{modal.unassign && <Modal onDismiss={() => setModal({ unassign: undefined })}>
			<UnassignPrize supermarket_id={modal.unassign.supermarket_id} prize={modal.unassign.prize}
				onFinish={() => {
					setState({ loading_allocations: true })
					setState({ loading_prizes: true })
					setModal({ unassign: undefined })
				}}/>
		</Modal>}
		</>
	}

	return <section className="p-6">
		<h1>Premios</h1>

		<ul className="tabs mt-6">
			<li 
				onClick={() => setMode(0)}
				className={cx('tab', { active: mode === 0 })}>
				Todos
			</li>
			<li 
				onClick={async () => {
					if (prizeAllocations) 
						setMode(1)
					setState({ loading_allocations: true })
				}}
				className={cx('tab', { active: mode === 1 })}>
				Por supermercado
				{is.loading_allocations && <Icon icon='spinner' pulse/> }
			</li>
		</ul>

		{mode == 0? all_prizes() : per_supermarket()}
	</section>
}

function CreateEditPrize({ prize: prize_, onFinish }: { prize: PrizeSummarized | true, onFinish?: () => void }) {
	const [is, setState] = usePartialState({ working: false })
	const prize = useForm<PrizeData>({
		initial: {
			quantity:  1
		},
		validation: {
			name: validate.required,
			logo_data: d => d == null? null : /data:image\/.+?;base64,.+/.test(d)? null : 'Formato inválido.',
			quantity: d => prize_ === true && (d == null || !Number(d))? 'Requerido.' : null
		},
		validate_native: true
	})

	useEffect(() => {
		if (prize_ !== true)
			prize.setData({ name: prize_.name, description: prize_.description, quantity: prize_.counts.unassigned })
	}, [])

	const toast = useToast()
	async function create(data: Partial<PrizeData>) {
		setState({ working: true  })
		const { succeeded } = await api.prizes.create(data as any)
		setState({ working: false })
		if (!succeeded) return
		toast('success', 'Premio creado.')
		onFinish?.()
	}

	async function edit(data: Partial<PrizeData>) {
		setState({ working: true  })
		const { succeeded } = await api.prizes.edit((prize_ as PrizeSummarized).id, data as any)
		setState({ working: false })
		if (!succeeded) return
		toast('success', 'Premio actualizado.')
		onFinish?.()
	}

	return <form onSubmit={prize.submit(prize_ === true? create : edit)}>
	<h2 className="mb-6">{prize_ === true? 'Nuevo' : 'Editar'} premio</h2>
	<div className="flex gap-8">
		<div style={{ minWidth: 150 }}>
			<ImageInput 
				defaultImage={prize_ !== true && prize_.image_resource_id? api.resource(prize_.image_resource_id) : undefined} 
				square 
				onInput={(_, logo_data) => prize.setData({ logo_data })}/>
		</div>
		<div>
			<div>
				<label htmlFor="name" className="font-medium">Nombre</label><br/>
				<input {...prize.field('name')} className="input w-full" />
			</div>
			<div>
				<label htmlFor="description" className="font-medium">Descripción</label><br/>
				<textarea {...prize.field('description')} className="input w-full" />
			</div>
			{prize_ === true && <div>
				<label htmlFor="quantity" className="font-medium">Cantidad</label><br/>
				<input {...prize.field('quantity', { type: 'number', min: 1 })} className="input w-full" />
			</div>}
		</div>
	</div>
	<div className="flex justify-end mt-6">
		<button type="submit" disabled={!!is.working} className="button blue">
			{!is.working
				? <>
					<Icon icon="save"/>
					{prize_ === true? 'Crear' : 'Guardar'}
				</> : <>
					<Icon icon="spinner" pulse/>
					{prize_ === true? 'Creando' : 'Guardando'}...
				</>}
		</button>
	</div>
</form>
}

function RestockPrize({ prize, adding, onFinish }: { prize: PrizeSummarized, adding: boolean, onFinish?: () => void }) {
	const [is, setState] = usePartialState({ working: false })
	const [amount, setAmount] = useState(1)

	const toast = useToast()
	async function restock() {
		setState({ working: true  })
		const { succeeded } = await api.prizes.edit(prize.id, {
			name: prize.name,
			description: prize.description,
			quantity: prize.counts.unassigned + (amount * (adding? 1 : -1)),
		})
		setState({ working: false })
		if (!succeeded) return
		toast('success', 'Inventario actualizado.')
		onFinish?.()
	}

	return <form onSubmit={event => {
		event.preventDefault()
		restock()
	}}>
		<h2 className="mb-6">
			{adding? 'Agregar' : 'Remover'} {prize.name}
		</h2>

		<input 
			type="number"
			value={amount}
			onChange={({ target: { value } }) => setAmount(Number(value))}
			min={1} 
			max={!adding? prize.counts.unassigned : undefined} 
			className="input w-full" />

		<div className="flex justify-end mt-6">
			<button disabled={is.working} className="button blue">
				{!is.working
					? <>
						<Icon icon="save"/>
						Guardar
					</> : <>
						<Icon icon="spinner" pulse/>
						Guardando...
					</>}
			</button>
		</div>
	</form>
}

function AssignPrizes({ supermarkets, prize, onFinish }: { supermarkets: { id: number, name: string }[], prize: { id: number, name: string, quantity: number }, onFinish?: () => void }) {
	const assignment = useForm({
		initial: {
			market_id: supermarkets.filter(s => s)[0].id,
			quantity: 1
		},
		validate_native: true
	})
	const [working, setWorking] = useState(false)

	const toast = useToast()
	async function assign(data: typeof assignment.data) {
		if (data.market_id == null) {
			toast('error', 'Debe seleccionar un supermercado.')
			return
		}
		setWorking(true)
		const { succeeded } = await api.prizes.assign(prize.id, data.quantity!, undefined, data.market_id!)
		setWorking(false)
		if (!succeeded) return
		toast('success', 'Inventario asignado.')
		onFinish?.()
	}
	return <form onSubmit={assignment.submit(assign)}>
		<h2 className="mb-6">Asignar '{prize.name}' a..</h2>

		<div className="flex flex-col">
			<strong>Supermercado</strong>
			<select value={assignment.data.market_id} onChange={({ target: { value } }) => assignment.setData({ market_id: Number(value) })} className="input w-full">
				{supermarkets.filter(s => s).map(market =>
					<option value={market.id}>{market.name}</option>
				)}
			</select>
		</div>

		<div className="flex flex-col mt-3">
			<strong>Cantidad</strong>
			<input {...assignment.field('quantity', { type: 'number', min: 1, max: prize.quantity })} className="input w-full" />
		</div>

		<div className="flex justify-end mt-6">
			<button disabled={working} className="button blue">
				<Icon icon="truck-arrow-right"/>
				Asignar
			</button>
		</div>
	</form>
}

function TransferPrize({ supermarkets, supermarket_id, prize, onFinish }: { supermarkets: { id: number, name: string }[], supermarket_id: number, prize: { id: number, name: string, quantity: number }, onFinish?: () => void }) {
	const [working, setWorking] = useState(false)
	const [assignment, setAssignment] = usePartialState({
		market_id: supermarkets.filter(s => s).filter(s => s.id !== supermarket_id)[0].id,
		quantity: 1
	})

	const toast = useToast()
	async function transfer() {
		if (assignment.market_id == null) {
			toast('error', 'Debe seleccionar un supermercado.')
			return
		}
		setWorking(true)
		const { succeeded } = await api.prizes.assign(prize.id, assignment.quantity, supermarket_id, assignment.market_id)
		setWorking(true)
		if (!succeeded) return
		toast('success', 'Inventario transferido.')
		onFinish?.()
	}

	return <form onSubmit={event => {
		event.preventDefault()
		transfer()
	}}>
		<h2 className="mb-6">Transferir '{ prize.name }' a...</h2>

		<div className="flex flex-col">
			<strong>Supermercado:</strong>
			<select value={assignment.market_id} onChange={({ target: { value } }) => setAssignment({ market_id: Number(value) })} className="input">
				{supermarkets.filter(s => s).filter(s => s?.id !== supermarket_id).map(market =>
					<option value={market!.id}>{market!.name}</option>
				)}
			</select>
		</div>

		<div className="flex flex-col mt-3">
			<strong>Cantidad</strong>
			<input type="number" value={assignment.quantity} onChange={({ target: { value } }) => setAssignment({ quantity: Number(value) })} min={1} max={prize.quantity} className="input" />
		</div>

		<div className="flex justify-end mt-6">
			<button disabled={working} className="button blue">
				{!working
					? <>
						<Icon icon="right-left"/>
						Transferir
					</> : <>
						<Icon icon='spinner' pulse/>
						Transfiriendo...
					</>}
			</button>
		</div>
	</form>
}

function UnassignPrize({ supermarket_id, prize, onFinish }: { supermarket_id: number, prize: { id: number, name: string, quantity: number }, onFinish?: () => void }) {
	const [amount, setAmount] = useState(1)
	const [working, setWorking] = useState(false)
	const toast = useToast()
	async function unassign() {
		setWorking(true)
		const { succeeded } = await api.prizes.assign(prize.id, amount, supermarket_id)
		setWorking(false)
		if (!succeeded) return
		toast('success', 'Inventario desasignado.')
		onFinish?.()
	}

	return <form onSubmit={event => {
		event.preventDefault()
		unassign()
	}}>
		<h2 className="mb-6">Des-asignar '{prize.name}'...</h2>

		<div>
			<label htmlFor="quantity" className="font-medium">Cantidad</label>
			<input 
				id="quantity" 
				type="number" 
				value={amount} 
				onChange={({ target: { value } }) => setAmount(Number(value))} 
				min={1}
				max={prize.quantity}
				className="input w-full"/>
		</div>

		<div className="flex justify-end mt-6">
			<button disabled={working} className="button yellow">
				Desasignar
			</button>
		</div>
	</form>
}