import { Customer } from "../data/models";

declare global {
	const XLSX: any
}

export function random(lower_bound: number, upper_bound: number): number {
	return Math.floor(lower_bound + Math.random() * (upper_bound - lower_bound + 1));
}

export function create_element<K extends keyof HTMLElementTagNameMap>(tag_name: K, options: { 'class'?: string[] | string; text?: string } & { [attribute: string]: any }): HTMLElementTagNameMap[K] {
	const element = document.createElement(tag_name);
	for (let attribute of Object.keys(options)) {
		if (attribute == 'class') {
			const class_option = options[attribute];
			if (typeof class_option === 'object')
				for (let class_name of options[attribute] as string[])
					element.classList.add(class_name);
			else if (typeof class_option === 'string')
				element.className = class_option;
			continue;
		}
		if (attribute == 'text') {
			element.textContent = options[attribute] as string;
			continue;
		}
		element.setAttribute(attribute, options[attribute]);
	}
	return element;
}

type RemoveListenerFunction = () => void;
export function global_listener<T extends keyof WindowEventMap>(type: T, selector: string | null | undefined, callback: (event: WindowEventMap[T]) => void): RemoveListenerFunction {
	const listener = (event: WindowEventMap[T]) => {
		if (selector && !(event.target as HTMLElement)?.matches(selector))
			return;
		callback?.(event);
	};
	window.addEventListener(type, listener);
	return () => window.removeEventListener(type, listener);
}

export function global_hotkey(keys: string | string[], callback: () => void): RemoveListenerFunction {
	keys = (typeof keys === 'string'
		? keys.split('+').map(key => key.trim().toLowerCase())
		: keys.map(key => key.toLowerCase()));
	keys = keys.map(k => k === 'space'? ' ' : k)
	const listener = (event: KeyboardEvent) => {
		let { key, shiftKey, ctrlKey, altKey } = event;
		key = key.toLowerCase();
		if (
			(!['shift', 'ctrl', 'alt'].includes(key) && keys.includes(key)) &&
			(!keys.includes('shift') || shiftKey) &&
			(!keys.includes('ctrl')  || ctrlKey) &&
			(!keys.includes('alt')   || altKey)
		) {
			callback();
			event.preventDefault();
			event.stopPropagation();
		}
	};
	window.addEventListener('keydown', listener);
	return () => window.removeEventListener('keydown', listener);
}

export function range(count: number): number[];
export function range(start: number, end: number): number[];
export function range(start_or_count: number, end?: number): number[] {
   if (end === undefined) {
      end = start_or_count
      start_or_count = 0;
   }
   const result: number[] = [];
   for (let i = start_or_count; i < end; i++)
      result.push(i);
   return result;
}

export function matches<T>(item: T, term: string): boolean {
   if (!item) return false;
   if (!term) return true;

   term = term.toLowerCase();
   switch (typeof item) {
      case 'string':
         return item.toLowerCase().includes(term);
      case 'number': case 'bigint':
         let str = item.toString();
         if (!str.includes('.'))
            str += '.00';
         return str.includes(term);
      case 'object':
         if (Array.isArray(item))
            return item.some(o => matches(o, term));
         return Object.values(item).some(v => matches(v, term));
      case 'boolean':
         return item.toString() === term;
      default:
         return false;
   }
}

export function debounce<F extends (...args: any[]) => void>(callback: F, delay_in_milliseconds: number): (...args: Parameters<F>) => void {
	let timeout: any;
	return (...args: Parameters<F>) => {
		if (timeout)
			clearTimeout(timeout);

		timeout = setTimeout(() => {
			callback(...args);
		}, delay_in_milliseconds);
	};
}

export function throttle<F extends (...args: any[]) => void>(callback: F, delay_in_milliseconds: number): (...args: Parameters<F>) => void {
	let blocked: boolean = false;
   let waiting_args: Parameters<F> | null;
   let check = () => {
      if (waiting_args) {
         callback(...waiting_args);
         waiting_args = null;
         setTimeout(check, delay_in_milliseconds);
      } else blocked = false;
   }
	return (...args: Parameters<F>) => {
		if (blocked) {
         waiting_args = args;
         return;
      }

		callback(...args);
		blocked = true;
		setTimeout(check, delay_in_milliseconds);
	};
}

// -----------------------
// --- ARRAY FUNCTIONS ---
// =======================
export function distinct<T, K>(collection: T[], key?: (item: T) => K): T[] {
	if (key)
		return collection?.filter((item, index) => {
			const item_key = key(item);
			return collection.findIndex(i => key(i) == item_key) === index;
		})
	return collection?.filter((item, index) => collection.indexOf(item) === index);
}

export function group<T, K>(collection: T[], key: (item: T) => K): { key: K; items: T[] }[] {
	const groups = new Map<K, T[]>();
	for (let item of collection) {
		const key_value = key(item);
		if (groups.has(key_value))
			groups.get(key_value)?.push(item);
		else
			groups.set(key_value, [item]);
	}
	return [...groups.entries()].map(([key, items]) => ({ key, items }));
}

export function chunk<T>(collection: T[], chunk_length: number): T[][] {
	if (collection?.length <= chunk_length)
		return [collection?.slice()];
	const chunks: T[][] = [];
	for (let i = 0; i < collection?.length; i += chunk_length)
		chunks.push(collection?.slice(i, i + chunk_length));
	return chunks;
}

export function split_into<T>(collection: T[], count: number): T[][] {
	return chunk(collection, Math.ceil(collection?.length / count));
}

export function interspace<T>(collection: T[], interspaced_element: T): T[] {
	if (!collection?.length)
		return collection;
	const interspaced = [collection[0]];
	for (let element of collection.slice(1))
		interspaced.concat([ interspaced_element, element ]);
	return interspaced;
}

function sample<T>(collection: T[]): T;
function sample<T>(collection: T[], weighter: (item: T, index: number) => number): T;
function sample<T>(collection: T[], count: number): T[];
function sample<T>(collection: T[], count_or_weighter?: number | ((item: T, index: number) => number)): T | T[] {
	if (count_or_weighter == undefined)
		return collection[random(0, collection.length - 1)]
	if (typeof count_or_weighter === 'function') {
		const weighted_items = collection.map((item, index) => {
			const weight = count_or_weighter(item, index)
			if (weight < 0)
				throw new Error('Weight cannot be negative')
			return { item, weight }
		})
		const total_weight = sum(weighted_items.map(wi => wi.weight))
		if (!total_weight) throw new Error('All weights cannot be 0')
		const choice = random(0, total_weight - 1)
		let i = 0
		for (let wi of weighted_items) {
			i += wi.weight
			if (i > choice)
				return wi.item
		}
		throw new Error('Unreachable')
	} else {
		collection = collection.slice();
		const sampled: T[] = [];
		while (sampled.length < count_or_weighter)
			sampled.push(collection.splice(random(0, collection.length - 1), 1)[0]);
		return sampled;
	}
}

export function shuffle<T>(collection: T[]): T[] {
	return sample(collection, collection.length)
}

export function sort_by<T, K>(collection: T[], key: (item: T) => K, ascending: boolean = true): T[] {
	collection = collection?.slice();
	return collection.sort((a, b) => {
		const ka = key(a);
		const kb = key(b);
		if (ascending)
			return <any>ka - <any>kb;
		else
			return <any>kb - <any>ka;
	})
}

export function flatten<T>(collection: T[][]): T[] {
	return collection?.reduce((flat, next) => flat.concat(next), []);
}

export function sum(collection: number[]): number {
	return collection?.reduce((accumulator, number) => accumulator + number, 0) ?? 0;
}

const months_es = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
export function format_date(datelike: Date | string | number, language: 'es' | 'en' = 'es'): string {
	const date_ = date(datelike)
	switch (language) {
		case 'es':
			return `${date_.getDate()} de ${months_es[date_.getMonth()]}, ${date_.getFullYear()}`
		case 'en':
			// @TODO(Gorky)
			return date_.toDateString()
	}
}
export function format_datetime(datelike: Date | string | number, language: 'es' | 'en' = 'es'): string {
	const date_ = date(datelike)
	let hours = date_.getHours()
	hours = hours > 12? hours - 12 : hours == 0? 12 : hours
	return `${hours.toString().padStart(2, '0')}:${date_.getMinutes().toString().padStart(2, '0')} ${date_.getHours() > 12? 'pm' : 'am'}, ${format_date(date_, language)}`
}

export function format_shortdate(datelike: Date | string | number): string {
	const date_ = date(datelike)
	return `${date_.getFullYear()}-${(date_.getMonth() + 1).toString().padStart(2, '0')}-${date_.getDate().toString().padStart(2, '0')}`
}

export function date(datelike: Date | string | number): Date {
	return new Date(typeof datelike === 'string' && datelike.length <= 10? datelike+'T00:00' : datelike)
}

export function date_add(datelike: Date | string | number, { years, months, days }: { years?: number, months?: number, days?: number }): Date {
	let date_ = date(datelike)
	if (years)  date_.setFullYear(date_.getFullYear() + years )
	if (months) date_.setMonth(   date_.getMonth()    + months)
	if (days)   date_.setDate(    date_.getDate()     + days  )
	return date_
}

export function export_players_as_xlsx(players: Customer[], filename: string): string | undefined {
	if (!players.length) return
	
	function snake_case_to_capital(text: string): string {
		const spaced = text.replaceAll(/_(.)/g, ' $1').toLowerCase().trim()
		return spaced.length > 1? spaced[0].toUpperCase() + spaced.substring(1) : spaced.toUpperCase()
	}
	
	const book = XLSX.utils.book_new()

	book.SheetNames.push('Participantes'); {
		const data = players.map(c => ({
			id:           c.id,
			nombre:       c.name,
			cedula:       c.document,
			telefono:     c.phone,
			correo:       c.email,
			provincia:    c.supermarket.address.region.toUpperCase(),
			ciudad:       c.supermarket.address.city.toUpperCase(),
			sector:       c.supermarket.address.sector.toUpperCase(),
			supermercado: c.supermarket.name.toUpperCase(),
			premio:       c.prize.name.toUpperCase(),
			usuario:      c.user.username,
			fecha:        date(c.date),
			productos:    c.products.map(p => `${p.quantity} ${p.name}`).join(', ')
		}))
		const headers = Object.keys(data[0])
		const rows = data.map(item => headers.map(h => (item as any)[h]))
		const sheet = XLSX.utils.aoa_to_sheet([headers.map(snake_case_to_capital) as (string | number | undefined)[]].concat(rows))
		book.Sheets['Participantes'] = sheet
	}

	XLSX.writeFileXLSX(book, filename)
}